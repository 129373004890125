@import "~@strata/tempo/lib/styles/Base.css";
@import "~@strata/tempo/lib/styles/Components.css";

body {
    background: linear-gradient(180deg, #007C9F 0%, #003848 100%);
    background-attachment: fixed;
}

.idp-outerdiv {
    min-height: 100vh;
}

.idp-innerdiv {
    display: flex;
    flex-direction: column;
	align-items: center;
	height: 100%;
    padding: 0px 16px;
    padding-bottom: 120px;
    position: relative;
}

.idp-logolink-container {
   	margin-top: 120px;
}

.idp-logolink-image {
    height: 51px;
    width: 180px;
}

.idp-footer {
    text-align: center;
    padding-bottom: 16px;
    flex-shrink: 0;
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
}

.idp-error-message-box{
    margin: 24px -32px -24px -32px;
    background-color: #FEEDEC;
    border-radius: 0px 0px 4px 4px;
    text-align: center;
    padding: 16px 24px;
}

.idp-inactive-logout-message-box {
    margin: -24px -32px 24px -32px;
    background-color: #C3E5EC;
    border-radius: 0px 0px 4px 4px;
    text-align: center;
    padding: 16px 24px;
    color: #005F7A;
}

#cardyBoi {
    margin: 0;
    width: calc(100vw - 32px);
    max-width: 344px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 24px;
    flex: 1 0 auto;
    border: none;
}

.idp-logolink-container {
    margin-bottom: 32px;
}

@media only screen and (max-width: 767px) {
    #cardyBoi{
        padding: 16px;
    }

    .idp-logolink-container {
        margin-top: 64px;
    }
}